<template>
  <div>
    <b-card no-body>
      <div class="card-header">
        <b-badge
          variant="light-primary"
          class="font-small-4 px-1 py-50"
        >
          THÔNG TIN CƠ BẢN
        </b-badge>
      </div>

      <div class="card-body">
        <div>
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <dynamic-form
                :id="'user-form-hoten'"
                :value="user.fullname"
                :type="'text'"
                :label="'Họ tên'"
                placeholder="Họ tên"
                required="required"
                :class="'form-group-hor'"
                @update-value="val => user.fullname = val"
              />

              <dynamic-form
                :id="'product-form-phanquyen'"
                :value="user.role"
                :type="'select'"
                :label="'Phân quyền'"
                required="required"
                :select-default="false"
                :class="'form-group-hor'"
                :options="ROLES"
                @update-value="val => user.role = val"
              />

              <dynamic-form
                v-if="user.role === 'user' || user.role === 'manager_branch'"
                :id="'product-form-branch_id'"
                :value="user.branch_id"
                :type="'select'"
                :label="'Chi nhánh'"
                text-field="name"
                value-field="id"
                required="required"
                :select-default="false"
                :class="'form-group-hor'"
                :options="branches"
                @update-value="val => user.branch_id = val"
              />

              <dynamic-form
                :id="'user-form-email'"
                :type="'text'"
                :value="user.email"
                :label="'Email'"
                placeholder="Email"
                required="required email"
                :class="'form-group-hor'"
                @update-value="val => user.email = val"
              />

              <dynamic-form
                v-if="pageMode === 'create'"
                :id="'user-form-password'"
                :type="'text'"
                :value="user.password"
                :label="'Mật khẩu'"
                placeholder="Mật khẩu"
                required="required password"
                :class="'form-group-hor'"
                @update-value="val => user.password = val"
              />

              <dynamic-form
                :id="'user-form-phone'"
                :type="'text'"
                :value="user.phone"
                :label="'Số điện thoại'"
                placeholder="Số điện thoại"
                required="phone"
                :class="'form-group-hor'"
                @update-value="val => user.phone = val"
              />

            </b-col>

            <b-col
              cols="12"
              md="3"
            >
              <div class="d-flex flex-column h-100">
                <label>Ảnh đại diện:</label>
                <div class="flex-grow-1 mb-1">
                  <b-avatar
                    size="150px"
                    class="avatar-border-2 box-shadow-1 badge-minimal user-avatar"
                    :src="getUrlFile(user.avatar, today)"
                    rounded
                    variant="light-primary"
                    alt="Ảnh đại diện"
                  >
                    <feather-icon
                      v-if="!user.avatar"
                      icon="UserIcon"
                      size="22"
                    />
                  </b-avatar>
                </div>
                <div
                  v-if="ability().can('edit', 'User')"
                  class=""
                >
                  <uploads
                    id="uploads"
                    ref="uploads"
                    class="hidden-all-item upload-image"
                    :files="files"
                    :options="{textDisplay: 'Tải ảnh lên'}"
                    :method-name="'uploadAvatar'"
                    @file-uploaded="handleUploaded"
                    @file-removed="handleRemoved"
                  />
                </div>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="3"
              class="d-flex flex-column"
            >
              <div class="form-group-hor mb-1 font-medium-1">
                <label>Ngày tạo:</label>
                <div class="text-right">
                  <strong>{{ formatDate(user.created_at) }}</strong>
                </div>
              </div>

              <div class="flex-grow-1">
                <div
                  v-if="ability().can('edit', 'User')"
                  class="d-flex justify-content-between mb-2"
                >
                  <span class="text-primary">Khóa tài khoản</span>
                  <b-form-checkbox
                    v-model="user.is_locked"
                    class="d-flex justify-content-end"
                    name="is-vertical-menu-collapsed"
                    switch
                    inline
                  />
                </div>

                <b-button
                  v-if="pageMode === 'edit' && ability().can('edit', 'User')"
                  variant="outline-primary"
                  class="mb-1 w-100"
                  @click="openModalChangePassword"
                >
                  Đổi mật khẩu
                </b-button>
                <b-button
                  v-if="pageMode === 'edit' && ability().can('edit', 'Admin')"
                  variant="outline-warning"
                  class="mb-1 w-100"
                  @click="openModalChangeCustomer"
                >
                  Chuyển khách hàng
                </b-button>
              </div>

              <div class="d-flex gap-1 justify-content-end flex-wrap">

                <b-button
                  v-if="pageMode === 'edit' && ability().can('delete', 'User')"
                  variant="danger"
                  class="flex-grow-1"
                  @click="openModalConfirmDeleteUser"
                >
                  <feather-icon icon="XIcon" />
                  Xóa
                </b-button>
                <b-button
                  v-if="pageMode === 'edit' && ability().can('edit', 'User')"
                  variant="primary"
                  class="flex-grow-1"
                  @click="handleUpdateUser"
                >
                  <feather-icon icon="SaveIcon" />
                  Lưu
                </b-button>
                <b-button
                  v-if="pageMode === 'create' && ability().can('create', 'User')"
                  class="flex-grow-1"
                  variant="success"
                  @click="handleCreateUser"
                >
                  <feather-icon icon="SaveIcon" />
                  Tạo
                </b-button>
              </div>

            </b-col>
          </b-row>
        </div>
      </div>
    </b-card>

    <b-card no-body>
      <div class="card-header">
        <b-badge
          variant="light-primary"
          class="font-small-4 px-1 py-50"
        >
          THIẾT LẬP THÔNG BÁO
        </b-badge>
      </div>

      <div class="card-body">
        <div>
          <dynamic-form
            :id="'product-form-tinhtrang'"
            :disabled="ability().can('update', 'UserProductNotify')"
            :value="user.client_type_config"
            :type="'multi-select'"
            :label="'Tình trạng'"
            multiple="multiple"
            :select-default="false"
            :class="'form-group-hor'"
            :options="STATUS_TYPE_CUSTOMER"
            @update-value="val => user.client_type_config = val"
          />
          <div class="d-flex justify-content-end">

            <div class="d-flex gap-1 justify-content-end flex-wrap">
              <b-button
                variant="primary"
                class="flex-grow-1"
                @click="handleUpdateUser"
              >
                <feather-icon icon="SaveIcon" />
                Lưu
              </b-button>
            </div>

          </div>
        </div>
      </div></b-card>

    <b-card
      v-if="isNotRoleHCNS"
      no-body
    >
      <div class="card-header">
        <b-badge
          variant="light-primary"
          class="font-small-4 px-1 py-50"
        >
          THIẾT LẬP XEM SẢN PHẨM
        </b-badge>
      </div>

      <div class="card-body">
        <div>
          <!--              KHÁCH SẠN -->
          <dynamic-form
            :id="'user-form-is_access_hotel'"
            :type="'checkbox'"
            :value="user.is_access_hotel"
            :sub-label="'Được xem mục khách sạn'"
            :class="'form-group-hor'"
            @update-value="val => user.is_access_hotel = val"
          />

          <b-row>
            <b-col
              cols="12"
              sm="6"
            >

              <p>
                <small
                  v-if="user.is_access_hotel"
                >(<span class="text-danger">*</span>) Chỉ xem được số điện thoại của khách sạn có giá nhỏ hơn thiết lập</small>
              </p>

              <dynamic-form
                v-if="user.is_access_hotel"
                :id="'user-form-is_limit_hotel_price_show_phone'"
                :value="user.is_limit_hotel_price_show_phone"
                :type="'radio'"
                :label="'Số điện thoại'"
                :class="'form-group-hor'"
                :options="[{value: false, text: 'Tất cả'}, {value: true, text: 'Giá nhỏ hơn'}]"
                @update-value="val => user.is_limit_hotel_price_show_phone = val"
              />

              <dynamic-form
                v-if="user.is_access_hotel && user.is_limit_hotel_price_show_phone"
                :id="'user-form-limit_hotel_price_show_phone'"
                :type="'number'"
                :value="user.limit_hotel_price_show_phone"
                :label="'Thuê nhỏ hơn'"
                placeholder="Ngưỡng giá cao nhất"
                append-text="VNĐ"
                :class="'form-group-hor sub-text'"
                @update-value="val => user.limit_hotel_price_show_phone = val"
              />
            </b-col>
            <b-col
              cols="12"
              sm="6"
            >
              <p>
                <small
                  v-if="user.is_access_hotel"
                >(<span class="text-danger">*</span>) Chỉ xuất hiện các khách sạn có giá nhỏ hơn thiết lập</small>
              </p>

              <dynamic-form
                v-if="user.is_access_hotel"
                :id="'user-form-is_limit_hotel_price_show_product'"
                :value="user.is_limit_hotel_price_show_product"
                :type="'radio'"
                :label="'Sản phẩm'"
                :class="'form-group-hor'"
                :options="[{value: false, text: 'Tất cả'}, {value: true, text: 'Giá nhỏ hơn'}]"
                @update-value="val => user.is_limit_hotel_price_show_product = val"
              />

              <dynamic-form
                v-if="user.is_access_hotel && user.is_limit_hotel_price_show_product"
                :id="'user-form-limit_hotel_price_show_product'"
                :type="'number'"
                :value="user.limit_hotel_price_show_product"
                :label="'Giá nhỏ hơn'"
                placeholder="Ngưỡng giá cao nhất"
                append-text="VNĐ"
                :class="'form-group-hor sub-text'"
                @update-value="val => user.limit_hotel_price_show_product = val"
              />
            </b-col>
          </b-row>

        </div>
        <hr>
        <div>

          <!--              NHA THUE -->
          <dynamic-form
            :id="'user-form-is_access_townhouse_hire'"
            :type="'checkbox'"
            :value="user.is_access_townhouse_hire"
            :sub-label="'Được xem mục nhà thuê'"
            :class="'form-group-hor'"
            @update-value="val => user.is_access_townhouse_hire = val"
          />

          <b-row>
            <b-col
              cols="12"
              sm="6"
            >
              <p>
                <small
                  v-if="user.is_access_townhouse_hire && user.limit_townhouse_hire_price_show_phone"
                >(<span class="text-danger">*</span>) Chỉ xem được số điện thoại của căn nhà thuê có giá nhỏ hơn thiết lập</small>
              </p>

              <dynamic-form
                v-if="user.is_access_townhouse_hire"
                :id="'user-form-is_limit_townhouse_price_show_phone_hire'"
                :value="user.is_limit_townhouse_price_show_phone_hire"
                :type="'radio'"
                :label="'Số điện thoại'"
                :class="'form-group-hor'"
                :options="[{value: false, text: 'Tất cả'}, {value: true, text: 'Giá nhỏ hơn'}]"
                @update-value="val => user.is_limit_townhouse_price_show_phone_hire = val"
              />

              <dynamic-form
                v-if="user.is_access_townhouse_hire && user.is_limit_townhouse_price_show_phone_hire"
                :id="'user-form-limit_townhouse_hire_price_show_phone'"
                :type="'number'"
                :value="user.limit_townhouse_hire_price_show_phone"
                :label="'Thuê nhỏ hơn'"
                placeholder="Ngưỡng giá cao nhất"
                append-text="VNĐ"
                :class="'form-group-hor sub-text'"
                @update-value="val => user.limit_townhouse_hire_price_show_phone = val"
              />

            </b-col>
            <b-col
              cols="12"
              sm="6"
            >
              <p>
                <small
                  v-if="user.is_access_townhouse_hire && user.is_limit_townhouse_price_show_product_hire"
                >(<span class="text-danger">*</span>) Chỉ xuất hiện các căn nhà thuê có giá nhỏ hơn thiết lập</small>
              </p>

              <dynamic-form
                v-if="user.is_access_townhouse_hire"
                :id="'user-form-is_limit_townhouse_price_show_product_hire'"
                :value="user.is_limit_townhouse_price_show_product_hire"
                :type="'radio'"
                :label="'Sản phẩm'"
                :class="'form-group-hor'"
                :options="[{value: false, text: 'Tất cả'}, {value: true, text: 'Giá nhỏ hơn'}]"
                @update-value="val => user.is_limit_townhouse_price_show_product_hire = val"
              />

              <dynamic-form
                v-if="user.is_access_townhouse_hire && user.is_limit_townhouse_price_show_product_hire"
                :id="'user-form-limit_townhouse_hire_price_show_product'"
                :type="'number'"
                :value="user.limit_townhouse_hire_price_show_product"
                :label="'Giá nhỏ hơn'"
                placeholder="Ngưỡng giá cao nhất"
                append-text="VNĐ"
                :class="'form-group-hor sub-text'"
                @update-value="val => user.limit_townhouse_hire_price_show_product = val"
              />
            </b-col>
          </b-row>

        </div>
        <hr>
        <div>
          <!--              nha ban -->
          <dynamic-form
            :id="'user-form-is_access_townhouse_sell'"
            :type="'checkbox'"
            :value="user.is_access_townhouse_sell"
            :sub-label="'Được xem mục nhà bán'"
            :class="'form-group-hor'"
            @update-value="val => user.is_access_townhouse_sell = val"
          />

          <b-row>
            <b-col
              cols="12"
              sm="6"
            >
              <p>
                <small
                  v-if="user.is_access_townhouse_sell && user.is_limit_townhouse_price_show_phone_sell"
                >(<span class="text-danger">*</span>) Chỉ xem được số điện thoại của căn nhà bán có giá nhỏ hơn thiết lập</small>
              </p>

              <dynamic-form
                v-if="user.is_access_townhouse_sell"
                :id="'user-form-is_limit_townhouse_price_show_phone_sell'"
                :value="user.is_limit_townhouse_price_show_phone_sell"
                :type="'radio'"
                :label="'Số điện thoại'"
                :class="'form-group-hor'"
                :options="[{value: false, text: 'Tất cả'}, {value: true, text: 'Giá nhỏ hơn'}]"
                @update-value="val => user.is_limit_townhouse_price_show_phone_sell = val"
              />

              <dynamic-form
                v-if="user.is_access_townhouse_sell && user.is_limit_townhouse_price_show_phone_sell"
                :id="'user-form-limit_townhouse_sell_price_show_phone'"
                :type="'number'"
                :value="user.limit_townhouse_sell_price_show_phone"
                :label="'Thuê nhỏ hơn'"
                placeholder="Ngưỡng giá cao nhất"
                append-text="VNĐ"
                :class="'form-group-hor sub-text'"
                @update-value="val => user.limit_townhouse_sell_price_show_phone = val"
              />

            </b-col>
            <b-col
              cols="12"
              sm="6"
            >

              <p>
                <small
                  v-if="user.is_access_townhouse_sell && user.is_limit_townhouse_price_show_product_sell"
                >(<span class="text-danger">*</span>) Chỉ xuất hiện các căn nhà bán có giá nhỏ hơn thiết lập</small>
              </p>

              <dynamic-form
                v-if="user.is_access_townhouse_sell"
                :id="'user-form-is_limit_townhouse_price_show_product_sell'"
                :value="user.is_limit_townhouse_price_show_product_sell"
                :type="'radio'"
                :label="'Sản phẩm'"
                :class="'form-group-hor'"
                :options="[{value: false, text: 'Tất cả'}, {value: true, text: 'Giá nhỏ hơn'}]"
                @update-value="val => user.is_limit_townhouse_price_show_product_sell = val"
              />

              <dynamic-form
                v-if="user.is_access_townhouse_sell && user.is_limit_townhouse_price_show_product_sell"
                :id="'user-form-limit_townhouse_sell_price_show_product'"
                :type="'number'"
                :value="user.limit_townhouse_sell_price_show_product"
                :label="'Giá nhỏ hơn'"
                placeholder="Ngưỡng giá cao nhất"
                append-text="VNĐ"
                :class="'form-group-hor sub-text'"
                @update-value="val => user.limit_townhouse_sell_price_show_product = val"
              />
            </b-col>
          </b-row>

        </div>
        <hr>
        <div>

          <dynamic-form
            :id="'user-form-is_access_office'"
            :type="'checkbox'"
            :value="user.is_access_office"
            placeholder="Được xem mục văn phòng"
            :sub-label="'Được xem mục văn phòng'"
            :class="'form-group-hor'"
            @update-value="val => user.is_access_office = val"
          />

        </div>

        <hr>
        <div>
          <label class="custom-checkbox-label mb-0">
            Giới hạn truy cập nhà thuê
          </label>
          <p />
          <b-row>
            <b-col
              cols="12"
              sm="6"
            >
              <dynamic-form
                :id="'user-form-price_limit'"
                :type="'number'"
                :value="user.price_limit"
                :label="'Giá'"
                placeholder="Giá"
                append-text="VNĐ"
                :class="'form-group-hor'"
                @update-value="val => user.price_limit = val"
              />
            </b-col>
            <b-col
              cols="12"
              sm="6"
            >
              <dynamic-form
                :id="'user-form-limit_req_higher'"
                :type="'number'"
                :value="user.limit_req_higher"
                :label="'Limit giá lớn hơn'"
                placeholder="Limit giá lớn hơn"
                append-text="/ngày"
                :class="'form-group-hor'"
                @update-value="val => user.limit_req_higher = val"
              />
            </b-col>
            <b-col
              cols="12"
              sm="6"
            >
              <dynamic-form
                :id="'user-form-limit_req_lower'"
                :type="'number'"
                :value="user.limit_req_lower"
                :label="'Limit giá nhỏ hơn'"
                placeholder="Limit giá nhỏ hơn"
                append-text="/ngày"
                :class="'form-group-hor'"
                @update-value="val => user.limit_req_lower = val"
              />
            </b-col>
          </b-row>
        </div>

        <div class="d-flex justify-content-end">

          <div class="d-flex gap-1 justify-content-end flex-wrap">

            <b-button
              v-if="pageMode === 'edit' && ability().can('update', 'UserLimit')"
              variant="primary"
              class="flex-grow-1"
              @click="handleUpdateUserLimit"
            >
              <feather-icon icon="SaveIcon" />
              Lưu
            </b-button>
          </div>

        </div>
      </div>
    </b-card>

    <LoginRequest
      v-if="isNotRoleHCNS"
      :user-device-id="user.id.toString()"
      :title="'THIẾT BỊ TRUY CẬP'"
    />

    <b-card
      v-if="pageMode === 'edit'"
      no-body
    >
      <div class="card-header">
        <b-badge
          variant="light-primary"
          class="font-small-4 px-1 py-50"
        >
          BÁO CÁO
        </b-badge>
        <div class="d-flex align-items-center">
          <v-select
            v-model="monthFilter"
            class="select-size-sm mr-25"
            label="name"
            :options="MONTH_OPTIONS"
            :searchable="false"
          />
          <v-select
            v-model="yearFilter"
            class="select-size-sm"
            label="name"
            :options="YEAR_OPTIONS"
            :searchable="false"
          />
        </div>
      </div>

      <div class="card-body">
        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex w-250 justify-content-between">
              <span class="mr-1">Nguồn nhà phố tìm về</span>
              <strong
                v-if="userReportData"
                class="text-primary"
              >{{ userReportData.sum_source_townhouse }}</strong>
            </div>

            <div
              v-if="userData.role !== 'user'"
              class="d-flex w-250 justify-content-between"
            >
              <span class="mr-1">Nhập nhà phố</span>
              <strong
                v-if="userReportData"
                class="text-primary"
              >{{ userReportData.sum_user_townhouse }}</strong>
            </div>

            <div
              v-if="userData.role !== 'user'"
              class="d-flex w-250 justify-content-between"
            >
              <span class="mr-1">Nhập văn phòng</span>
              <strong
                v-if="userReportData"
                class="text-primary"
              >{{ userReportData.sum_user_office }}</strong>
            </div>

            <div
              v-if="ability().can('show-total_sum_user_office_price', 'Report')"
              class="d-flex w-250 justify-content-between"
            >
              <span class="mr-1">Nhà thuê trên 90 triệu</span>
              <strong
                v-if="userReportData"
                class="text-primary"
              >{{ userReportData.total_sum_user_office_price_high }} căn</strong>
            </div>

            <div
              v-if="ability().can('show-total_sum_user_office_price', 'Report')"
              class="d-flex w-250 justify-content-between"
            >
              <span class="mr-1">Nhà thuê dưới 90 triệu</span>
              <strong
                v-if="userReportData"
                class="text-primary"
              >{{ userReportData.total_sum_user_office_price_low }} căn</strong>
            </div>

            <div class="d-flex w-250 justify-content-between">
              <span class="mr-1">Nhà bán</span>
              <strong
                v-if="userReportData"
                class="text-primary"
              >{{ userReportData.total_sum_user_townhouse_sell }} căn</strong>
            </div>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <div
              v-if="userData.role !== 'supporter'"
              class="d-flex w-250 justify-content-between text-primary cursor-pointer"
              @click="goListCustomerCorner({user_id: user.id, monthFilter: monthFilter, yearFilter: yearFilter, username: user.fullname})"
            >
              <span class="mr-1">Số khách nhà phố:</span>
              <strong
                v-if="userReportData"
                class="text-primary"
              >{{ userReportData.sum_client_townhouse }}</strong>
            </div>

            <div
              v-if="userData.role !== 'supporter'"
              class="d-flex w-250 justify-content-between"
            >
              <span class="mr-1">Số khách văn phòng:</span>
              <strong
                v-if="userReportData"
                class="text-primary"
              >{{ userReportData.sum_client_office }}</strong>
            </div>
          </b-col>
        </b-row>
        <br>
        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <div>
              <label class="text-primary font-weight-bolder">Sản phẩm nhà phố</label>
              <hr class="mt-0">
              <vue-apex-charts
                :key="'user-chart-product-townhouse-' +keyRefreshComponent"
                :options="productChartTownhouse.options"
                :series="productChartTownhouse.series"
              />
            </div>
            <div>
              <label class="text-primary font-weight-bolder">Sản phẩm văn phòng</label>
              <hr class="mt-0">
              <vue-apex-charts
                :key="'user-chart-product-office-' +keyRefreshComponent"
                :options="productChartOffice.options"
                :series="productChartOffice.series"
              />
            </div>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <div>
              <label class="text-primary font-weight-bolder">Khách hàng nhà phố</label>
              <hr class="mt-0">
              <vue-apex-charts
                :key="'user-chart-customer-townhouse-' +keyRefreshComponent"
                :options="customerChartTownhouse.options"
                :series="customerChartTownhouse.series"
              />
            </div>
            <div>
              <label class="text-primary font-weight-bolder">Khách hàng văn phòng</label>
              <hr class="mt-0">
              <vue-apex-charts
                :key="'user-chart-customer-townhouse-' +keyRefreshComponent"
                :options="customerChartOffice.options"
                :series="customerChartOffice.series"
              />
            </div>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <UserDetailActivity
      ref="userDetailActivity"
      :user-id="$route.params.id"
    />

    <modal-change-password
      id="modal-admin-change-password"
      ref="modal-admin-change-password"
      modal-mode="admin-change-password"
      :user="user"
    />
    <modal-change-customer
      id="modal-admin-change-customer"
      ref="modal-admin-change-customer"
      modal-mode="admin-change-customer"
      :user="user"
    />

    <b-modal
      ref="modal-confirm-delete-user"
      cancel-title="Hủy"
      ok-title="Xác nhận"
      centered
      header-text-variant="danger"
      title="Xác nhận xóa nhân viên!"
      @ok="handleDeleteUser"
    >
      <b-card-text>
        Bạn thực sự muốn <span class="text-danger">xóa {{
          (ROLES.find(item => item.value === user.role) || {text: ''}).text.toLowerCase()
        }}</span>?
        <br>
        <span class="text-primary font-weight-bolder">{{ user.fullname || user.phone || user.username }}</span>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BAvatar, BBadge, BButton, BCard, BCardText, BCol, BFormCheckbox, BRow,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import VueApexCharts from 'vue-apexcharts'
import Ripple from 'vue-ripple-directive'
import { onUnmounted } from '@vue/composition-api'
import useUserDetail from '@/views/user/detail/useUserDetail'
import store from '@/store'
import userStoreModule from '@/views/user/userStoreModule'
import ModalChangePassword from '@/views/settings/users/list/ModalChangePassword.vue'
import ModalChangeCustomer from '@/views/settings/users/list/ModalChangeCustomer.vue'
import DynamicForm from '@core/dynamic-form/DynamicForm.vue'
import ability from '@/libs/acl/ability'
import { subject } from '@casl/ability'
import Uploads from '@core/components/uploads/Uploads.vue'
import LoginRequest from '@/views/login-request/LoginRequest.vue'
import fileIcon from 'vue-feather-icons/icons/FileIcon'
import { getUserData } from '@/auth/utils'
import UserDetailActivity from './UserDetailActivity.vue'

export default {
  components: {
    LoginRequest,
    VueApexCharts,
    vSelect,
    BCard,
    BButton,
    BRow,
    BCol,
    ModalChangePassword,
    ModalChangeCustomer,
    DynamicForm,
    BFormCheckbox,
    BBadge,
    BCardText,
    Uploads,
    BAvatar,
    UserDetailActivity,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      ability,
      subject,
    }
  },
  computed: {
    isNotRoleHCNS() {
      const user = getUserData()
      return user.role !== 'personnel_administration'
    },
    fileIcon() {
      return fileIcon
    },
    chartOptionsProduct() {
      return this.chartOptions
    },
    chartOptionsCustomer() {
      return this.chartOptions
    },
  },
  watch: {
    '$route.fullPath': {
      handler(n) {
        if (n) {
          const qId = this.$route.params.id
          if (qId) {
            this.pageMode = 'edit'
            this.$call(this.fetchUser(qId, res => {
              Object.assign(this.user, res.data)
              this.fetchReportUser()
            }))
          } else {
            this.pageMode = 'create'
          }
        }
      },
      immediate: true,
    },
    userChartData(n) {
      if (!n) return
      const p = n.product
      const c = n.client
      const co = n.client_office
      const po = n.product_office
      // eslint-disable-next-line no-nested-ternary
      const xLabelFormat = !this.yearFilter ? 'yyyy' : this.monthFilter ? 'dd-MM' : 'MM-yy'

      this.customerChartTownhouse.series[0].data = c.map(item => parseInt(item.total_product, 10))
      this.customerChartTownhouse.options.xaxis.categories = [...c.map(item => item.time)]
      this.customerChartTownhouse.options.xaxis.labels.format = xLabelFormat
      this.keyRefreshComponent += 1

      this.productChartTownhouse.series[0].data = p.map(item => parseInt(item.total_product, 10))
      this.productChartTownhouse.options.xaxis.categories = p.map(item => item.time)
      this.productChartTownhouse.options.xaxis.labels.format = xLabelFormat
      this.keyRefreshComponent += 1

      this.customerChartOffice.series[0].data = co.map(item => parseInt(item.total_product, 10))
      this.customerChartOffice.options.xaxis.categories = [...co.map(item => item.time)]
      this.customerChartOffice.options.xaxis.labels.format = xLabelFormat
      this.keyRefreshComponent += 1

      this.productChartOffice.series[0].data = po.map(item => parseInt(item.total_product, 10))
      this.productChartOffice.options.xaxis.categories = po.map(item => item.time)
      this.productChartOffice.options.xaxis.labels.format = xLabelFormat
      this.keyRefreshComponent += 1
    },
  },
  mounted() {
    if (this.$route.query.open === 'report') {
      this.$refs.userDetailActivity.visible = true
      setTimeout(() => {
        const el = this.$refs.userDetailActivity.$el
        const y = el.getBoundingClientRect().top + window.pageYOffset
        window.scrollTo({ top: y, behavior: 'smooth' })
      }, 1000)
      this.$router.replace({ query: null })
    }
  },
  created() {
    this.$store.dispatch('app/fetchBranches')
  },
  methods: {
    goListCustomerCorner(filter) {
      this.$router.push({ name: 'customer-corner-list', query: { filter: JSON.stringify(filter) } })
    },
    handleUploaded(files = []) {
      this.files.push(...files)
    },
    handleRemoved(file) {
      const foundIdx = this.files.findIndex(f => f.name === file.name)
      if (foundIdx !== -1) {
        this.files.splice(foundIdx, 1)
      }
    },
    openModalConfirmDeleteUser() {
      this.$refs['modal-confirm-delete-user'].show()
    },
    openModalChangePassword() {
      this.$refs['modal-admin-change-password'].$refs['btn-open-modal-change-password'].click()
    },
    openModalChangeCustomer() {
      this.$refs['modal-admin-change-customer'].$refs['btn-open-modal-change-customer'].click()
    },
    handleDeleteUser() {
      this.$call(this.deleteUser(this.user.id, () => {
        this.$router.push({ name: 'user-list' })
      }), true)
    },
    handleUpdateUser() {
      this.$call(this.updateUser(this.user, res => {
        Object.assign(this.user, res.data)
      }), true)
    },
    handleUpdateUserLimit() {
      this.$call(this.updateUserLimit(this.user), true)
    },
    handleCreateUser() {
    },
  },
  setup() {
    const USER_STORE_MODULE_NAME = 'user'
    if (!store.hasModule(USER_STORE_MODULE_NAME)) store.registerModule(USER_STORE_MODULE_NAME, userStoreModule)
    onUnmounted(() => {
      if (store.hasModule(USER_STORE_MODULE_NAME)) store.unregisterModule(USER_STORE_MODULE_NAME)
    })

    return {
      ...useUserDetail(),
    }
  },
}
</script>

<style>
</style>
