<template>
  <div>
    <b-button
      ref="btn-open-modal-change-customer"
      v-b-modal="id"
      class="d-none"
    />
    <b-modal
      :id="id"
      :ref="id"
      no-close-on-backdrop
      ok-title="Chuyển khách hàng"
      cancel-title="Hủy"
      header-text-variant="primary"
      title="Chuyển khách hàng"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <b-form-group
        label="Chọn nhân viên"
        label-for="select_user"
      >
        <v-select
          v-model="userId"
          :options="optionsUser"
          :input-id="'value'"
          :reduce="option => option['value']"
          :clearable="false"
          :close-on-select="true"
          placeholder="Chọn nhân viên"
        />
      </b-form-group>
      <span
        v-if="modalErrorText"
        class="text-danger"
      >{{ modalErrorText }}</span>
    </b-modal>
  </div>
</template>

<script>
import { BButton, BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'
import { onUnmounted, ref } from '@vue/composition-api'
import store from '@/store'
import userStoreModule from '@/views/settings/users/userStoreModule'
import useUsersList from '@/views/settings/users/list/useUsersList'

export default {
  components: {
    vSelect,
    BFormGroup,
    BButton,
  },
  props: {
    id: {
      type: String,
      default: 'create',
    },
    modalMode: {
      type: String,
      default: 'user-change-customer',
    },
    user: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    optionsUser() {
      return this.usersOrigin.map(user => ({
        value: user.id,
        label: user.fullname,
      }))
    },
  },
  watch: {
    userId: {
      handler() {
        this.modalErrorText = ''
      },
      deep: true,
    },
  },
  mounted() {
    // eslint-disable-next-line no-underscore-dangle
    window.__modalChangeCustomer = this
    this.fetchUsers()
  },
  methods: {

    async handleOk(event) {
      event.preventDefault()
      if (!this.userId) {
        this.modalErrorText = 'Vui lòng chọn nhân viên'
        return
      }
      try {
        this.$call(store.dispatch('user/changeCustomer', {
          user_id: this.user.id,
          new_user_id: this.userId,
        }), true)
        // if (this.modalMode === 'user-change-password') {
        //   await this.changePassword()
        // }
        // if (this.modalMode === 'admin-change-password') {
        //   await this.adminChangePassword()
        // }
        setTimeout(() => {
          this.hideModal(this.id)
        })
      } catch (e) {
        console.log(e.message, e)
        this.modalErrorText = e.message || e
      }
    },
  },
  setup() {
    const USER_STORE_MODULE_NAME = 'user'
    if (!store.hasModule(USER_STORE_MODULE_NAME)) store.registerModule(USER_STORE_MODULE_NAME, userStoreModule)
    onUnmounted(() => {
      if (store.hasModule(USER_STORE_MODULE_NAME)) store.unregisterModule(USER_STORE_MODULE_NAME)
    })

    const userId = ref(null)
    const modalErrorText = ref('')
    const {
      fetchUsers,
      usersOrigin,
    } = useUsersList()

    const resetModal = () => {
      userId.value = null
      modalErrorText.value = ''
    }

    return {
      resetModal,
      userId,
      modalErrorText,
      fetchUsers,
      usersOrigin,
    }
  },
}
</script>
